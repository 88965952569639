import React from 'react';
import Navigation from '../Navigation';
import { Link } from 'react-router-dom';
// Material UI
import Container from '@material-ui/core/Container';
const About = (props) => {
    const products = [
        {
            title: '',
            image: 'https://cdn.shopify.com/s/files/1/0093/3625/8657/products/138-8_6be6977d-80d2-4360-b721-bac44a64643a_500x.jpg?v=1635954806',
            url: 'https://www.artscroll.com/Books/9781422611388.html',
            buyNow: true,
        },
        {
            title: (
                <>
                    Available in many seforim stores. (Also at ZBerman) If you
                    cannot find it near you, you can reach out to:
                    <br />
                    <span
                        className="email mr-5"
                        data-email="seforim@torahstream.org"
                    ></span>{' '}
                    <br />
                    <span
                        className="email"
                        data-email="chukoseyhem@torahstream.org"
                    ></span>
                </>
            ),
            image: 'assets/2023-06-19_21-59.png',
            url: 'https://judaicaplaza.com/products/6197066',
            buyNow: false,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener Nisyonos, Bitachon, and Tefilah Series (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/products/6197067_600x.jpg?v=1689778722',
            url: 'https://judaicaplaza.com/products/6197067?_pos=3&_sid=60577d0f7&_ss=r',
            buyNow: true,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener Inyunei Mishpacha Shiurim (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/products/6197066_500x.jpg?v=1689778719',
            url: 'https://judaicaplaza.com/products/6197066',
            buyNow: true,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener - Parshah (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/files/6197112_1_c1fa1481-2f1f-4b1d-9e91-f609035b0bdc_400x.jpg?v=1725465008',
            url: 'https://judaicaplaza.com/products/6197112?_pos=1&_sid=4f8fe5479&_ss=r',
            buyNow: true,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener - Tzedakah (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/files/6197114_1_3d7f0b2f-03fb-4bb2-892d-b0ff16e5b497_400x.jpg?v=1725465014',
            url: 'https://judaicaplaza.com/products/6197114?_pos=2&_sid=4f8fe5479&_ss=r',
            buyNow: true,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener - Sefer Melachim (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/files/6197116_1_44d2946a-909f-47de-ad77-5c3224ae44b8_300x.jpg?v=1725465020',
            url: 'https://judaicaplaza.com/products/6197116?_pos=3&_sid=4f8fe5479&_ss=r',
            buyNow: true,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener - Sefer Bereishis (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/files/6197072_1_300x.jpg?v=1694451066',
            url: 'https://judaicaplaza.com/products/6197072?_pos=4&_sid=4f8fe5479&_ss=r',
            buyNow: true,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener - Lifnei Iver (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/files/6197117_1_d79c4ba0-6e8f-43cd-89ff-8b6472737735_400x.jpg?v=1725465023',
            url: 'https://judaicaplaza.com/products/6197117?_pos=5&_sid=4f8fe5479&_ss=r',
            buyNow: true,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener - Chukoseihem Lo Seileichu (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/files/6197113_1_bf1f74c1-9d1c-4ae7-a78b-52efaf341b23_400x.jpg?v=1725465011',
            url: 'https://judaicaplaza.com/products/6197113?_pos=6&_sid=4f8fe5479&_ss=r',
            buyNow: true,
        },
        {
            title: "Torah 2 Go: R' Yosef Viener - Navi Sefer Shoftim (USB)",
            image: 'https://judaicaplaza.com/cdn/shop/files/6197115_1_a9d46081-5161-4b19-9687-c3af3156d0fb_400x.jpg?v=1725465016',
            url: 'https://judaicaplaza.com/products/6197115?_pos=7&_sid=4f8fe5479&_ss=r',
            buyNow: true,
        },
    ];
    return (
        <Container>
            <Navigation />
            <h1 className="text-5xl py-8">Welcome to TorahStream </h1>
            <div className="flex mb-10 flex-col md:flex-row">
                <div className="w-2/3 mr-20 rounded-md">
                    <img
                        alt="Rav Viener"
                        id="rav-viener-picture"
                        src="assets/rabbi_viener.jpg"
                        className="w-96"
                    />
                </div>
                <div className="text-xl">
                    <div>
                        Rabbi Yosef Viener, author of{' '}
                        <Link to="http://www.artscroll.com/Books/cqhh.html">
                            {' '}
                            Contemporary Questions in Halachah and Hashkafah
                        </Link>
                        , has inspired and enlightened listeners worldwide with
                        his shiurim on Halachah and Hashkafah - crystallizing
                        the Torah's timeless wisdom and applying to our daily
                        lives. TorahStream.org is proud to bring you{' '}
                        <Link to="/shiurim">these fascinating lectures</Link> so
                        you too can benefit from his Torah knowledge and
                        practical life guidance at the time and place of your
                        convenience.
                    </div>
                    <div className="pt-6">
                        <a
                            href="/shiurim"
                            className="bg-blue-400 p-3 my-5 rounded-md"
                        >
                            Go To Shiurim
                        </a>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 mb-10">
                {products.map((product) => (
                    <div className="flex flex-col p-2" key={product.title}>
                        <img
                            className="lg:w-2/3 w-full p-5 mx-auto"
                            src={product.image}
                        />
                        <div className="mx-auto">
                            {product.title}
                            <div className="pt-5 text-center">
                                {product.buyNow && (
                                    <a
                                        href={product.url}
                                        className="bg-blue-400 p-3 my-5 rounded-md"
                                    >
                                        Buy Now
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Container>
    );
};
export default About;
